import "core-js"
import "regenerator-runtime/runtime"
import "./polyfills.js"
import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom"
import whyDidYouRender from "@welldone-software/why-did-you-render"
import { createBrowserHistory } from "history"
import { setCapnVersionForAmplitude } from "./common/amplitude-event-logging"
import { LicenseManager } from "ag-grid-enterprise"
import store from "./store"
//import axe from "@axe-core/react"

// In development, make whyDidYouRender available.
// See: https://github.com/welldone-software/why-did-you-render
if (process.env.NODE_ENV !== "production") {
    whyDidYouRender(React, {
        // You can track third party components via. the include option, e.g.
        // include: [/^AgGridReact/]
    })

    // Uncomment this statement and the import statement in order to enable axe and review
    // your work for accessibiliy issues
    //axe(React, ReactDOM, 1000)
}

// Set up the session manager & history stack. This must happen before
// importing any other app code
import Rmbx from "./util"
Rmbx.util.history = createBrowserHistory()
Rmbx.util.setup()
window.Rmbx = Rmbx

import registerServiceWorker from "./registerServiceWorker"
import Router from "./router"

if (window.sentry_dsn) {
    const serverErrorsRegex = new RegExp("(status|response) 403|response 401", "mi")
    Sentry.init({
        dsn: window.sentry_dsn,
        ignoreErrors: [serverErrorsRegex],
        release: window.capn_version,
    })
}

if (window.amplitude_key) {
    // amplitude is initalized in the rmbx_minimal script tag, but we want to set the capn version
    setCapnVersionForAmplitude(window.capn_version)
}

import "main.less"
import "../fonts/icormbx/style.css"
import "react-select-v1/dist/react-select.css"

LicenseManager.setLicenseKey(
    // eslint-disable-next-line max-len
    "Using_this_{AG_Grid}_Enterprise_key_{AG-051118}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Rhumbix,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Rhumbix_Analytics}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Rhumbix_Analytics}_need_to_be_licensed___{Rhumbix_Analytics}_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_January_2025}____[v3]_[01]_MTczNzg0OTYwMDAwMA==614d323396f177576ae6532156554434"
)

const rootEl = document.getElementById("master-container")
const history = Rmbx.util.history

const render = App => {
    ReactDOM.render(<App history={history} store={store} />, rootEl)
}

render(Router)

// Enable hot module replacement (HMR) for the <Router/> component
if (module.hot) {
    module.hot.accept("./router", () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        render(require("./router").default)
    })
}

// Register a service worker. This should come last, since if the app crashes
// at any point above, we don't want to start caching the broken-ness.
registerServiceWorker()
