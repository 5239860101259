import makeRequest from "../networkClient"
import { _formatDate, _post, _put } from "./util"
import { urlForResource } from "../common/constants"
import Rmbx from "../util"
import { getFlagEnabled } from "../getFlagValue"

export const addWorkerToShift = (workShift, employeeId) =>
    _post(
        "/api/v3/employee_work_shifts/",
        {
            work_shift: workShift.id,
            employee: employeeId,
            state: "CLOSED",
        },
        201
    )

export const bulkCreateRows = (resourceName, data, expectedStatusCode = 200) =>
    _post(`${urlForResource[resourceName]}bulk_insert/`, data, expectedStatusCode)

export const bulkUpdateQuantities = qtyList =>
    _post("/api/v3/quantities/bulk_update/", { quantities: JSON.stringify(qtyList) }, 200)

export const changeCostCode = (workshiftId, data) =>
    _post(`/api/v3/work_shifts/${workshiftId}/change_cost_code/`, data)

export const changePassword = data => _post("/api/v3/users/change_password/", data)

export const createAbsence = data => _post("/api/v3/absences/", data, 201)

export const createApiTokenIntegrationName = (integrationName, coId, empId) =>
    _post("/api/v3/company_integration/", {
        company_integration_name: integrationName,
        company: coId,
        created_by: empId,
    })

export const createAutodeskToken = code => _post(urlForResource.autodeskToken, { code }, 201)

export const createCompanyFormStore = (body, fields = []) =>
    _post("/api/v4/company_form_stores/", body, 201, false, false, false, { fields })

export const generateS3Urls = (body, resource) => {
    const resourceUrl = resource ? urlForResource?.[resource] : null
    if (resourceUrl && getFlagEnabled("WA-8336-fix-lighbox-buckets")) {
        return _post(`${resourceUrl}bulk_reference/`, body)
    }
    return _post("/api/v4/company_form_stores/bulk_reference/", body)
}

// _post will append a trailing slash to "create_dependencies=True", but
// that's OK: create_dependencies can be any truthy value
export const createQuantity = (date, cc, value) =>
    _post(
        "/api/v3/quantities/?create_dependencies=True",
        {
            client_created: date,
            project_cost_code: "",
            cost_code: cc["id"],
            project: cc["project"],
            value: value,
        },
        201
    )

export const sendReferralEmails = data => _post("/api/v4/tm-invite/", data, 204)

export const createRow = (resourceName, data) => _post(urlForResource[resourceName], data, 201)

export const createWorkShiftV4 = params => _post("/api/v4/work_shifts/", params, 201)

export const upsertTimecard = (data, forceNewTimecard) => {
    return _post(
        `/api/v4/work_shifts/upsert_timecard/?force_new_timecard=${forceNewTimecard}`,
        data,
        200,
        false,
        false,
        true
    )
}

export const pasteCell = data => {
    return _post("/api/v4/work_shifts/paste_cell/", data, 200, false, false, true)
}

export const createWorkComponent = workComponent => _post("/api/v3/work_components/", workComponent, 201)

export const createWorkshift = workshift => _post("/api/v3/work_shifts/", workshift, 201)

export const deactivateIntegrationToken = id => _post(`/api/v3/company_integration/${id}/deactivate/`)

export const deleteItemsById = (resourceName, objectIds, projectId = null) =>
    _post(
        `${urlForResource[resourceName]}bulk_delete/`,
        {
            ids: objectIds,
            project_id: projectId,
        },
        204
    )

export const duplicateWorkshift = (workshiftId, newDate) =>
    _post(`/api/v3/work_shifts/${workshiftId}/duplicate/`, { new_date: _formatDate(newDate) }, 201)

export const login = data => _post("/api/v3/users/auth_token/", data)

export const loginCheck = data => _post("/api/v4/employees/login_check/", data)

export const guestLogin = data => _post("/api/v4/guest_login/", data)

export const sendFormData = (
    form_data,
    resource,
    method = "POST",
    apiVersion = 4,
    headers = null,
    extraParams = null
) => {
    const params = {
        ...{
            method: method,
            body: form_data,
        },
        ...extraParams,
    }
    if (headers) {
        params["headers"] = headers
    }
    return fetch(`/api/v${apiVersion}/${resource}/`, params)
}

export const sendForemanInvite = (companyId, data) =>
    _post(`/api/v3/companies/${companyId}/company_employees/invite/`, data)

export const sendMassText = (companyId, message, employeeIds) =>
    _post(`/api/v3/companies/${companyId}/company_employees/masstext/`, {
        message: message,
        employee_ids: employeeIds,
    })

export const regeneratePDF = formId => {
    return _post(
        "/api/v4/company_form_stores/regenerate_pdf/",
        {
            form_id: formId,
        },
        200
    )
}

export const updateCompanySignatureSettings = signatureSettings =>
    _post("/api/v4/company_options/", signatureSettings, 201)

export const updateItemsValueById = (
    resourceName,
    objectIds,
    field,
    value,
    updateUsingResponseData = false,
    hasSignal = false
) =>
    _post(
        `${urlForResource[resourceName]}bulk_update/`,
        {
            ids: objectIds,
            field: field,
            value: value,
            has_signal: hasSignal,
        },
        // updateUsingResponseData should be set to true
        // if we're returning response data from the bulk_update
        // backend endpoint for this resource (status 200)
        // rather than no data (status 204)
        updateUsingResponseData ? 200 : 204
    )

export const updateLockedPeriod = date => _post("/api/v4/company_options/", { lock_end_date: date }, 201)

export const updateGroupSchemaSettings = (schemaId, groupSchemaSettings) =>
    _post(`/api/v4/company_group_schemas/${schemaId}/update/`, groupSchemaSettings, 200)

export const updateSchemaProjectAssignments = (schemaId, projectAssignments) =>
    _put(`/api/v4/custom_form_schemas/${schemaId}/projects/`, projectAssignments, 200)

export const getPicklistGroupsAndProjects = (itemType, itemIds) =>
    _post("/api/v4/group_project_modifiers/group_project_tree/", {
        item_type: itemType,
        item_ids: itemIds,
    })

export const updatePicklistGroupsAndProjects = (itemType, itemIds, groupSettings, projectAssignments) =>
    _post("/api/v4/group_project_modifiers/bulk_update/", {
        item_type: itemType,
        item_ids: itemIds,
        groups: groupSettings,
        projects: projectAssignments,
    })

export const workflowActionById = (resourceName, objectIds, action, queryParams = {}) => {
    // adding ignore_schema_types allows us to update bundle stores, which don't come back in the default queryset
    if (resourceName === "companyFormStores") queryParams = { ...queryParams, ignore_schema_types: true }
    const queryString = Rmbx.util.serializeQueryParams(queryParams)

    // _post appends a trailing slash to the query string
    return makeRequest(
        {
            method: "POST",
            url: `${urlForResource[resourceName]}bulk_workflow_action/?${queryString}`,
            body: JSON.stringify({
                ids: objectIds,
                action: action,
            }),
        },
        200
    )
}

export const createDailySummaryPreference = dailySummaryPreference =>
    _post(urlForResource["dailySummaryPreferences"], dailySummaryPreference, 201)

export const transformDataAPI = (transform, only_from_selected_project = false) => {
    return _post(
        `/api/v4/company_form_stores/transform/?project_id=${transform.project_id}`,
        {
            transform_id: transform.transform_id,
            store_ids: transform.store_ids,
            project_id: transform.project_id,
            ...(getFlagEnabled("WA-7858-web-tk-transform-project-limited") && only_from_selected_project
                ? { only_from_selected_project: true }
                : {}),
            ...(getFlagEnabled("WA-8154-ff-variants") && transform?.variant_name
                ? { variant_name: transform?.variant_name }
                : {}),
        },
        200,
        false,
        false,
        true
    )
}

export const submitSelectedFormShareInfo = data => {
    return _post("/api/v4/guest_form_shares/share_form/", data, 200)
}

// this can go with "WA-8154-ff-variants"
export const sendGuestCopyOfSignedForm = (signed_key, originalPDFS3VersionId) => {
    return _post(
        "/api/v4/send_pdf_to_guest_user/",
        {
            signed_key: signed_key,
            previous_s3_pdf_version: originalPDFS3VersionId,
        },
        204
    )
}

export const removePreviousCollaborator = guest_id => {
    return _post(`/api/v4/guest_users/${guest_id}/delete/`, null, 200)
}

export const updatePermissions = (
    schemaId,
    statusName,
    userRole,
    companyRole,
    fieldPermissions,
    objectPermissions
) =>
    _post("/api/v4/schema_permissions/update_perms/", {
        schema_id: schemaId,
        status_name: statusName,
        user_role: userRole,
        company_role: companyRole,
        field_permissions: fieldPermissions,
        object_permissions: objectPermissions,
    })

export const getBulkFormStores = (idsList, page) => {
    let url = "/api/v4/company_form_stores/id_filter/"
    // used to get the next batch of form stores
    // this endpoint only returns 20 results per request
    if (page > 1) url += `?page=${page}`
    // the last parameter is to set skipTrailingSlash to true
    // when requesting a specific page
    const trailingSlash = page > 1
    return _post(url, { ids: idsList }, 200, false, false, trailingSlash)
}

export const getCompareBulkFormStore = idsList => {
    return _post("/api/v4/company_form_stores/compare/", { ids: idsList })
}

export const createSavedFilter = data => {
    return _post("/api/v4/saved_filter_sets/", data, 201)
}

export const getReferenceableDataByResourceAndIds = (resourceName, idsList, queryParams = "") => {
    // id_filter supports a POST where the IDs to retrieve are put in the body
    return _post(
        `${urlForResource[resourceName]}id_filter/${queryParams}`,
        { ids: idsList },
        200,
        false,
        false,
        true
    )
}

export const triggerGuestPdfDownload = (formId, signedKey, uniqueRequestId) => {
    // pass along a uniqueRequestId so we only send the resulting file to the browser that requested it
    // this prevents a double download if the user has more than one window open
    return _post(`/api/v4/company_form_stores/download_as_guest/${signedKey}`, {
        form_share_id: formId,
        unique_request_id: uniqueRequestId,
        signed_key: signedKey,
    })
}

export const triggerBulkPdfDownload = (storeIds, filename, uniqueRequestId, variantName) => {
    // pass along a uniqueRequestId so we only send the resulting file to the browser that requested it
    // this prevents a double download if the user has more than one window open
    return _post("/api/v4/company_form_stores/bulk_download_pdfs/", {
        store_ids: storeIds,
        filename,
        unique_request_id: uniqueRequestId,
        ...(getFlagEnabled("WA-8154-ff-variants") && variantName ? { variant: variantName } : {}),
    })
}

export const requestSignature = data => {
    return _post("/api/v4/request_signature/", data, 200)
}

export const createCompanySSOConfiguration = data => {
    return _post("/api/v4/company_sso_configuration/", data, 201)
}

export const refreshSSOToken = data => _post("/dj-rest-auth/oidc-refresh/", data, 200)

export const updateSchemaActions = payload => {
    return _post("/api/v4/schema_actions/bulk_update/", payload, 204)
}

export const updateSchemaStatuses = payload => {
    return _post("/api/v4/schema_status_names/bulk_update/", payload, 204)
}

export const addSchemaStatuses = payload => {
    return _post("/api/v4/schema_status_names/bulk_insert/", payload, 200)
}

export const addSchemaActions = payload => {
    return _post("/api/v4/schema_actions/bulk_insert/", payload, 201)
}

export const deleteSchemaActions = payload => _post("/api/v4/schema_actions/bulk_delete", payload, 204)

export const deleteSchemaStatuses = payload => _post("/api/v4/schema_status_names/bulk_delete", payload, 204)

export const updateWorkShiftProjects = (pk, payload) => _put(`/api/v4/work_shifts/${pk}/projects/`, payload, 200)

export const remapTkStatus = payload => _post("/api/v4/timekeeping_statuses/remap/", payload, 200)

export const addPicklistItem = (pk, payload) => _post(`/api/v4/picklists/${pk}/field_add_item/`, payload, 201)

export const remapPicklistItem = (id, payload) => _post(`/api/v4/picklist_items/${id}/remap/`, payload, 200)

export const getRemapPicklistItem = (id, payload) => _post(`/api/v4/picklist_items/${id}/get_remap/`, payload, 200)

export const getEmployeesByCompanySuppliedId = params =>
    _post("/api/v4/employees/by_company_supplied_id/", params, 200)

export const sendWelcomeEmail = data => _post("/api/v4/employees/welcome_email/", data, 204)

export const sendResetPasswordEmail = data => _post("/api/v4/employees/reset_password_email/", data, 204)

export const toggleEmployeeLogin = data => _post("/api/v4/employees/toggle_login/", data, 200)

export const toggleTextAlerts = data => _post("/api/v4/employees/toggle_text_alerts/", data, 200)
