import { isEmpty } from "lodash"
import React, { Component } from "react"

class CheckMarkCellRenderer extends Component {
    refresh() {
        return false
    }

    render() {
        const isNotEmptyCheck =
            (typeof this.props.value === "boolean" && this.props.value) || !isEmpty(this.props.value)
        return (
            <div>
                {isNotEmptyCheck ? (
                    <img className="check-mark" alt="check-mark" src={require("green-check.png")} />
                ) : null}
            </div>
        )
    }
}

export default CheckMarkCellRenderer
